import dayjs from "dayjs";
import {computed, createApp, h} from 'vue'
import vuetify from './plugins/vuetify.js';
import {createInertiaApp, router, usePage} from '@inertiajs/vue3'
import UserCanMixin from './Shared/Mixins/UserCan';
import * as Sentry from "@sentry/vue";
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import '../sass/Core/app.scss';
import '../sass/Mailings/MailBuilderPreview.scss';
import '../sass/Microsites/FormsDesignerPreview.scss';
import '../sass/app.scss';

import relative from 'dayjs/plugin/relativeTime';

import('./bootstrap.js');

dayjs.extend(relative);

createInertiaApp({
    resolve: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({
            render: () => h(App, props),

            data() {
                return {
                    currentPage: 0,
                }
            },
        })
            .use(plugin)
            .use(vuetify)
            .mixin(UserCanMixin);

        app.component("RouterLink", {
            useLink(props) {
                const href = props.to.value;
                const currentUrl = computed(() => usePage().url);
                return {
                    route: computed(() => ({href})),
                    isActive: computed(() => currentUrl.value.startsWith(href)),
                    isExactActive: computed(() => href === currentUrl),
                    navigate(e) {
                        if (e.shiftKey || e.metaKey || e.ctrlKey) return;
                        e.preventDefault();
                        router.visit(href);
                    }
                };
            }
        });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            trackComponents: true,
            environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
            release: import.meta.env.VITE_SENTRY_RELEASE,
        });

        app.mount(el);
    },
});
